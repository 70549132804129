<template>
  <div class="news-index">
    <div class="index-top">
      <img class="top-img" src="~@/assets/images/news-top-logo.jpg" alt="">
      <p class="top-title">匈牙利留学攻略 | 最新版！德布勒森留学生活指南</p>
      <p class="top-text">鉴于很多小伙伴踏上或未来将赴匈留学之旅，为了帮助大家迅速融入匈牙利留学生活</p>
    </div>
    <div class="index-content">
      <ul class="content-item">
        <nut-infiniteloading @loadmore="onInfinite" :is-show-mod="true" :has-more="isHasMore" :is-loading="isLoading" loading-txt="加载中" unload-more-txt="没有了哦~" :threshold="200">
          <li class="item-list" v-for="(item, index) in list" :key="index"  @click="goNews(item.href)">
            <img class="list-img" :src="item.img" alt="">
            <div class="list-info">
              <p class="info-title">{{ item.title }}</p>
              <p class="info-text">{{ item.type }}</p>
            </div>
          </li>
        </nut-infiniteloading>
      </ul>
    </div>
  </div>
</template>

<script>
import Api from '@/api/api.js'
export default {
  name: 'news-index',
  props: {},
  data () {
    return {
      isHasMore: true,
      isLoading: false,
      page: 1,
      list: [],
      totalPages: 1
    }
  },
  components: {},
  computed: {},
  created () {
    document.title = '新闻'
    this.getList()
  },
  watch: {},
  methods: {
    getList () {
      let that = this
      Api.post({
        url: 'news',
        data: {
          limit: 8,
          page: this.page
        },
        success: function (res) {
          if (res.code === 200 && res.data) {
            for (let i in res.data) {
              that.list.push(res.data[i])
            }
            that.totalPages = res.totalPages
          }
        }
      })
    },
    // 下拉加载
    onInfinite () {
      this.page++
      if (this.page <= this.totalPages) {
        this.getList()
      } else {
        this.isHasMore = false
        return false
      }
    },
    goNews (url) {
      window.open(url)
    }
  }
}
</script>

<style scoped>
.news-index{background-color:#fff;padding:16px;}
.index-top{padding-bottom:8px;border-bottom:1px solid #e0e0e0;}
.index-top .top-img{display:block;margin-bottom:12px;width:100%;border-radius:8px;}
.index-top .top-title{font-size:14px;line-height:20px;margin-bottom:6px;color:#333;}
.index-top .top-text{font-size:12px;line-height:20px;color:#999;}
.index-content .content-item .item-list{overflow:hidden;padding:12px 0;border-bottom:1px solid #e0e0e0;}
.index-content .content-item .item-list:last-child{border-bottom:none;}
.index-content .content-item .item-list .list-img{float:left;display: block;width:120px;height:70px;}
.index-content .content-item .item-list .list-info{margin-left:132px;}
.index-content .content-item .item-list .list-info .info-title{font-size:14px;color:#333;margin-bottom:6px;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;word-break: break-all;}
.index-content .content-item .item-list .list-info .info-text{font-size:12px;color:#999;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;padding-left:12px;position:relative;}
.index-content .content-item .item-list .list-info .info-text::after{content:'';position: absolute;width:8px;height:8px;border-radius:8px;background-color:#B7212B;left:0;top:50%;transform: translate(0,-50%);}
</style>
