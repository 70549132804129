<template>
  <div id="app">
    <router-view class="wrapper"></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
/* 公共样式 */
html,body{font-family:Arial,"Poppins",sans-serif;margin:0;padding:0;background-color:#f5f5f5;}
ul{margin:0;padding:0;list-style:none;outline:none;}
.wrapper a{display:inline-block;margin:0;padding:0;text-decoration:none;outline:none;}
.wrapper a:link,.wrapper a:visited,.wrapper a:hover,.wrapper a:active{text-decoration:none;outline:none;}
.wrapper p,.wrapper dl,.wrapper dd{margin:0;}
.pull-left{float:left!important;}
.pull-right{float:right!important;}
.text-right{text-align:right;}
.no-more{display:block;padding:15px 0;font-size:16px;color:#333;text-align:center;background-color:#fff;}
.alert-mes{position:fixed;top:50%;left:50%;z-index:2000;padding:15px;min-width:180px;max-width:240px;font-size:15px;color:#fff;text-align:center;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);-o-transform:translate(-50%,-50%);transform:translate(-50%,-50%);background:rgba(0,0,0,0.7);border-radius:5px;}
.el-upload .el-upload__input,.el-upload input[type=file]{display:none;}
.wrapper{background-color:#f5f5f5;margin:0 auto;max-width:720px;width:100%;overflow:hidden;box-sizing:border-box;}
.clear{clear:both;}
/* 公共头部 */
.public-top{margin-top:32px;background-color:#004735;padding:16px;position:relative;border-radius:8px;height:286px;box-sizing:border-box;}
.public-top .top-logo{position:absolute;display:block;width:100px;top:-20px;right:10px;}
.public-top .top-title{color:#fff;font-size:20px;margin-bottom:16px;}
.public-top .top-text{color:#fff;font-size:16px;margin-bottom:8px;}
.public-top .top-text.size{font-size:12px;line-height:16px;}
.public-top .top-info{background-color:#fff;padding:16px;border-radius:8px;position:absolute;width:92%;box-sizing:border-box;}
.public-top .top-info .info-title{font-size:16px;color:#B7212B;}
.public-top .top-info .info-text{overflow:hidden;font-size:12px;color:#999999;padding:16px 0;}
.public-top .top-info .info-text .text-self{margin-right:18px;}
.public-top .top-info .info-text .text-self.te{margin-right:0;}
.public-top .top-info .info-btn{text-align: center;background-color:#B7212B;color:#fff;line-height:36px;font-size:14px;border-radius:36px;}
/* 公共申请按钮 */
.index-content .content-box{height:76px;}
.index-content .content-btn{display:block;position:fixed;left:50%;bottom:85px;transform:translate(-50%,0);z-index:100;}
.index-content .content-btn .btn-self{display: block;width:294px;line-height:36px;color: #fff;background-color:#B7212B;text-align:center;border-radius:24px;margin:0 auto;font-size:12px;}
/* 资料公共样式 */
.index-content .content-material{background-color:#fff;border-radius:8px;overflow:hidden;padding:0 16px 16px;margin-bottom:8px;}
.index-content .content-material .material-title{line-height:34px;font-size:16px;position:relative;padding-left:32px;border-bottom:1px solid #E0E0E0;margin-bottom:10px;}
.index-content .content-material .material-title::after{content:'';position:absolute;width:8px;height:8px;background-color:#000;left:10px;top:50%;transform: translate(0,-50%);border-radius:88px;}
.index-content .content-material .material-info{overflow:hidden;}
.index-content .content-material .material-info .info-img{float:left;width:140px;position:relative;}
.index-content .content-material .material-info .info-img .img-self{display: block;width:100%;}
.index-content .content-material .material-info .info-img .img-text{width:56px;line-height:22px;font-size:12px;background-color:#B7212B;color:#fff;text-align: center;position:absolute;left:0;bottom:12px;border-radius:0 22px 22px 0;}
.index-content .content-material .material-info .info-copywriting{margin-left:160px;}
.index-content .content-material .material-info .info-copywriting .copywriting-title{font-size:16px;color:#000;font-weight:bold;margin-bottom:6px;}
.index-content .content-material .material-info .info-copywriting .copywriting-text{font-size:12px;color:#999999;line-height:22px;}
.index-content .content-material .material-info .info-copywriting .copywriting-btn{overflow:hidden;}
.index-content .content-material .material-info .info-copywriting .copywriting-btn .btn-self{float:right;width:72px;text-align:center;line-height:24px;color:#fff;background-color:#B7212B;font-size:12px;border-radius:24px;}
.content-item .arrange-img{display: block;width:100%;}
/* 视频公共样式 */
.index-content .content-material .material-video{height:200px;}
.index-content .content-material .material-video .video-list{margin-right:10px;position:relative;}
.index-content .content-material .material-video .video-list .video-list-self{width:100%;height:200px;display:block;}
.index-content .content-material .material-video .video-list .video-list-text{position:relative;background-color:#00473594;color:#fff;bottom:80px;left:50%;transform: translate(-50%, 0);width:232px;box-sizing: border-box;padding:12px;border-radius:8px;}
.index-content .content-material .material-video .video-list .video-list-text .text-title{font-size:14px;}
.index-content .content-material .material-video .video-list .video-list-text .text-self{font-size:12px;}
.index-content .content-material .material-video .video-list .video-list-text .text-icon{display: block;width:36px;height:36px;background:url('~@/assets/images/icon-play.png') center no-repeat;background-size:36px;position: absolute;top:-18px;right:10px;}
</style>
