<template>
  <div class="famous-schools-index">
    <div class="index-top">
      <video class="top-img" :src="videosList.video_path" :poster="videosList.image" controls></video>
      <!-- <img class="top-img" src="~@/assets/images/famous-schools-logo.png" alt=""> -->
    </div>
    <div class="index-content">
      <p class="content-title">学校列表</p>
      <div class="content-item">
        <nut-infiniteloading @loadmore="onInfinite" :is-show-mod="true" :has-more="isHasMore" :is-loading="isLoading" loading-txt="加载中" unload-more-txt="没有了哦~" :threshold="200">
          <div class="item-list" v-for="(item, index) in list" :key="index" @click="goPage('/product', item.id, item.name)">
            <div class="list-box">
              <div class="list-info">
                <img class="info-img" :src="item.img" alt="">
                <div class="info-self">
                  <p class="self-text">专业：{{ item.major_number }}</p>
                  <p class="self-text">学费范围：{{ item.tuition }}</p>
                </div>
              </div>
              <p class="list-title">{{ item.name }}</p>
            </div>
          </div>
        </nut-infiniteloading>
      </div>
      <div class="content-box">
				<div class="content-btn">
					<p class="btn-self" @click.stop="goPage('/applyFor')">填写申请</p>
				</div>
			</div>
    </div>
    <navIndex></navIndex>
  </div>
</template>

<script>
import Api from '@/api/api.js'
import navIndex from '@/components/public/nav.vue'
export default {
  name: 'famous-schools',
  props: {},
  data () {
    return {
      list: [],
      videosList: {},
      isHasMore: true,
      isLoading: false,
      total_pages: 0,
      page: 1,
    }
  },
  components: {
    navIndex
  },
  computed: {},
  created () {
    document.title = '匈牙利名校申硕'
    this.getColleges()
    this.getVideos()
  },
  watch: {},
  methods: {
    goPage (url, id, name) {
      this.$router.push({
        path: url,
        query: {
          id: id,
          name: name
        }
      })
    },
    // 获取视频
    getVideos () {
      let that = this
      Api.post({
        url: 'videos',
        data: {
          is_other_country: 1
        },
        success (res) {
          if (res.code === 200 && res.data) {
            that.videosList = res.data[0]
          }
        }
      })
    },
    getColleges () {
      let that = this
      Api.post({
        url: 'colleges',
        data: {
          limit: 10,
          page: this.page
        },
        success: function(res) {
          if (res.data && res.code === 200) {
            for (let i in res.data) {
              that.list.push(res.data[i])
            }
            that.total_pages = res.totalPages
          }
        }
      })
    },
    // 下拉加载
    onInfinite () {
      this.page++
      if (this.page <= this.total_pages) {
        this.getColleges()  
      } else {
        this.isHasMore = false
        return false
      }
    },
  }
}
</script>

<style scoped>
.famous-schools-index{background-color:#fff;padding:0 16px;}
.index-top{padding-top:16px;}
.index-top .top-img{display:block;width:100%;}
.content-title{font-size:16px;color:#000;line-height:36px;font-weight:600;}
.content-item{overflow:hidden;}
.content-item .item-list{display:block;width:50%;float:left;margin-bottom:10px;}
.content-item .item-list .list-box{padding:0 5px;}
.content-item .item-list .list-info{position:relative;}
.content-item .item-list .list-info .info-img{display:block;width:100%;height:136px;border-radius:8px;}
.content-item .item-list .list-info .info-self{position:absolute;width:100%;left:0;bottom:0;background:linear-gradient(to bottom,rgba(191,191,191,0.8) 1%,rgba(34,91,76,0.8) 22%,rgba(0,71,53,1) 100%);color:#fff;font-size:14px;box-sizing: border-box;padding:12px 10px;}
</style>
