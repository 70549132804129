<template>
  <div class="nanyang-summer-camp-index">
    <div class="index-top">
      <div class="top-img">
        <img class="img-bg" src="~@/assets/images/nylg-summer-camp-bg.png" alt="">
        <p class="img-title">南洋理工大学冬/夏令营</p>
      </div>
      <div class="top-info">   
        <div class="info-logo">
          <img class="logo-self" src="~@/assets/images/nylg-summer-camp-logo.png" alt="">
          <div class="logo-text">
            <p class="text-title">南洋理工大学，简称南大</p>
            <p class="text-self">Nanyang Technological University</p>
          </div>
        </div>
        <p class="info-text">新加坡科研密集型大学。南大是环太平洋大学联盟、全球大学校长论坛、新工科教育国际联盟成员，全球高校人工智能学术联盟创始成员、AACSB、国际事务专业学院协会成员，也是国际科技大学联盟的发起成员。南大被“QS世界大学排名”评为世界顶尖大学之一，并多年蝉联全球年轻大学榜首。</p>
        <div class="info-list">
          <span class="list-title">冬令营</span>
          <span class="list-text">2024年01月20日-01月29日 共10天</span>
        </div>
        <div class="info-list">
          <span class="list-title">夏令营</span>
          <span class="list-text">2024年07月/08月 共10天</span>
        </div>
      </div>
    </div>
    <div class="index-content">
      <div class="content-item">
        <p class="item-title">课程优势</p>
        <div class="item-advantage">
          <div class="advantage-box">
            <div class="advantage-list">
              <p class="list-text">南洋理工大学研学项目录取通知书</p>
            </div>
            <div class="advantage-list">
              <p class="list-text">南洋理工大学研学项目结业证书</p>
            </div>
            <div class="advantage-list">
              <p class="list-text">优秀学生可获得南洋理工大学推荐信</p>
            </div>
            <div class="advantage-list">
              <p class="list-text">南洋理工大学知名教授授课</p>
            </div>
          </div>
        </div>
      </div>
      <div class="content-item">
        <p class="item-title">行程安排</p>
        <div class="item-arrange">
          <img class="arrange-img" src="~@/assets/images/nylg-summer-camp-arrange.jpg" alt="">
        </div>
      </div>
      <div class="content-item">
        <p class="item-title">报名流程</p>
        <div class="item-procedure">
          <img class="procedure-bg" src="~@/assets/images/nylg-summer-camp-procedure.png" alt="">
          <div class="procedure-info">
            <div class="info-list">
              <span class="list-title">STEP1</span>
              <span class="list-text">·在线填写申请表，等待老师联系</span>
            </div>
            <div class="info-list">
              <span class="list-title">STEP2</span>
              <span class="list-text">·提交护照扫描件，交纳报名费</span>
            </div>
            <div class="info-list">
              <span class="list-title">STEP3</span>
              <span class="list-text">·完成签证办理并购买机票</span>
            </div>
            <div class="info-list">
              <span class="list-title">STEP4</span>
              <span class="list-text">·行前说明</span>
            </div>
            <div class="info-list">
              <span class="list-title">STEP4</span>
              <span class="list-text">·正式出行</span>
            </div>
          </div>
        </div>
      </div>
      <div class="content-box">
				<div class="content-btn">
					<p class="btn-self" @click="goPage('/applyForSpecialCamp')">填写申请</p>
				</div>
			</div>
    </div>
    <navIndex></navIndex>
  </div>
</template>

<script>
import navIndex from '@/components/public/nav.vue'
export default {
  name: 'nanyang-summer-camp',
  props: {},
  data () {
    return {
    }
  },
  components: {
    navIndex
  },
  computed: {},
  created () {
    document.title = '南洋理工大学夏令营'
  },
  watch: {},
  methods: {
    goPage (url) {
      this.$router.push({
        path: url,
        query: {
          type: 'nylg'
        }
      })
    }
  }
}
</script>

<style scoped>
.nanyang-summer-camp-index{background-color:#fff;}
.index-top{border-radius:16px;overflow:hidden;background-color:#B3040F;color:#fff;}
.index-top .top-img{position:relative;}
.index-top .top-img .img-bg{display: block;width:100%;}
.index-top .top-img .img-title{position:absolute;width:100%;text-align:center;top:24px;left:0;font-size:18px;}
.index-top .top-info{padding:16px;}
.index-top .top-info .info-logo{position:relative;}
.index-top .top-info .info-logo .logo-self{position:absolute;top:-80px;display: block;left:20px;}
.index-top .top-info .info-logo .logo-text{margin-left:140px;margin-bottom:10px;}
.index-top .top-info .info-logo .logo-text .text-title{font-size:18px;line-height:32px;}
.index-top .top-info .info-logo .logo-text .text-self{font-size:12px;color:#F0F5F3;opacity:0.7;}
.index-top .top-info .info-text{font-size:12px;opacity:0.9;line-height:20px;margin-bottom:8px;}
.index-top .top-info .info-list{border:1px solid #FFC300;margin-bottom:6px;line-height:34px;border-radius:8px;overflow:hidden;}
.index-top .top-info .info-list:last-child{margin-bottom:0;}
.index-top .top-info .info-list .list-title{display:inline-block;width:68px;text-align:center;background-color:#FFC300;font-size:16px;border-radius:0 8px 8px 0;}
.index-top .top-info .info-list .list-text{margin-left:12px;font-size:16px;}
.index-content .content-item{margin-top:12px;}
.index-content .content-item .item-title{padding:0 16px 12px;font-size:16px;color:#333;font-weight:bold;}
.index-content .content-item .item-advantage{padding:0 16px;}
.index-content .content-item .item-advantage .advantage-box{background-color:#F0F5F3;border-radius:8px;overflow:hidden;}
.index-content .content-item .item-advantage .advantage-list{padding:10px;float:left;width:50%;box-sizing:border-box;border-right:1px solid #fff;border-bottom:1px solid #fff;}
.index-content .content-item .item-advantage .advantage-list .list-text{padding:0 16px;font-size:14px;background:url('~@/assets/images/nylg-summer-camp-icon.png') left 4px no-repeat;background-size:14px;}
.index-content .content-item .item-procedure{position:relative;padding:0 16px;}
.index-content .content-item .item-procedure .procedure-bg{display: block;width:100%;}
.index-content .content-item .item-procedure .procedure-info{position:absolute;left:50%;top:50%;color:#fff;width:312px;transform: translate(-50%,-50%);}
.index-content .content-item .item-procedure .procedure-info .info-list{line-height:28px;margin-bottom:4px;font-size:14px;}   
.index-content .content-item .item-procedure .procedure-info .info-list .list-title{display:inline-block;width:66px;text-align:center;line-height:28px;background-color:#B3040F;border-radius:8px;}
.index-content .content-item .item-procedure .procedure-info .info-list .list-text{display:inline-block;width:236px;border-bottom:1px solid #fff;margin-left:10px;}
</style>
