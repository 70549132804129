<template>
  <div class="school detail-index">
		<!-- 头部 -->
		<div class="detail-top">
      <el-carousel height="260px" indicator-position="none">
        <el-carousel-item v-for="item in info.background_image" :key="item">
          <img class="detail-top-img" :src="item" alt="">
        </el-carousel-item>
      </el-carousel>
		</div>
		<!-- 内容 -->
		<div class="detail-content">
			<!-- 排名及录取率 -->
			<div class="content-top">
				<div class="top-list">
					<div class="list-title">{{info.name ? info.name : '汉语言文学'}}</div>
					<div class="list-text">{{info.name_en ? info.name_en : 'Chinese Language and Literature'}}</div>
				</div>
			</div>
			<div class="content-main">
				<div class="content-item">
					<div class="item-title te">专业介绍</div>
					<div class="item-box">
						<div class="item-text">{{info.description ? info.description : "暂无简介"}}</div>
					</div>
				</div>
				<div class="content-item" v-if="info.train_goals !== ''">
					<div class="item-title te">培养目标</div>
					<div class="item-box">
						<div class="item-text">{{info.train_goals ? info.train_goals : "暂无简介"}}</div>
					</div>
				</div>
				<div class="content-item">
					<div class="item-title te">专业优势</div>
					<div class="item-box">
						<div class="item-tag" v-if="info.advantage.length > 0">
							<text class="tag-list" v-for="(item, index) in info.advantage" :key="index">{{item}}</text>
						</div>
						<div class="item-tag" v-else>
							<div class="tag-text">暂无</div>
						</div>
					</div>
				</div>
				<div class="content-item">
					<div class="item-title ">专业相关信息</div>
						<div class="box-list te">
							<div class="list-info">
								<div class="info-left">学制</div>
								<div class="info-rig">{{info.duration ? info.duration : '暂无'}}</div>
							</div>
							<div class="list-info">
								<div class="info-left">学费</div>
								<div class="info-rig">{{info.tuition ? info.tuition : '暂无'}}</div>
							</div>
							<div class="list-info">
								<div class="info-left">授课语言</div>
								<div class="info-rig">{{info.language ? info.language : '暂无'}}</div>
							</div>
							<div class="list-info">
								<div class="info-left">入学要求</div>
								<div class="info-rig">{{info.admission_require ? info.admission_require : '暂无'}}</div>
							</div>
							<div class="list-info">
								<div class="info-left">入学时间</div>
								<div class="info-rig">{{info.admission_time ? info.admission_time : '暂无'}}</div>
							</div>
							<div class="list-info">
								<div class="info-left">申请时间</div>
								<div class="info-rig">{{info.apply_time ? info.apply_time : '暂无'}}</div>
							</div>
							<div class="list-info">
								<div class="info-left">所属院系</div>
								<div class="info-rig">{{info.faculty_name ? info.faculty_name : '暂无'}}</div>
							</div>
							<div class="list-info">
								<div class="info-left">就业前景</div>
								<div class="info-rig">{{info.job_prospects ? info.job_prospects : '暂无'}}</div>
							</div>
						</div>
					</div>
			</div>
		</div>
    <div class="index-content">
      <div class="content-box">
				<div class="content-btn">
					<p class="btn-self" @click="goPage('/applyFor')">填写申请</p>
				</div>
			</div>
    </div>
		<navIndex></navIndex>
	</div>
</template>

<script>
import Api from '@/api/api.js'
import navIndex from '@/components/public/nav.vue'
export default {
  name: 'professionalDetails',
  props: {},
  data () {
    return {
			major_id: 0,
			college_id: 0,
      info: {
        advantage: []
      }
    }
  },
  components: {
		navIndex
	},
  computed: {},
  created () {
    document.title = this.$route.query.title ? this.$route.query.title : 0
		this.major_id = this.$route.query.major_id ? this.$route.query.major_id : 0
		this.college_id = this.$route.query.college_id ? this.$route.query.college_id : 0
		this.getInfo()
  },
  watch: {},
  methods: {
    goPage (url) {
      this.$router.push({
        path: url,
				query: {
					major_id: this.major_id,
					college_id: this.college_id
				}
      })
    },
		getInfo () {
			let that = this
			Api.post({
				url: 'majors/show',
				data: {
					id: this.major_id
				},
				success: function(res) {
					if (res.code === 200 && res.data) {
						that.info = res.data
					}
				}
			})
		}
  }
}
</script>

<style scoped>
.detail-top-img{display:block;width:100%;}
.detail-content{position:relative;}
.detail-content .content-top{margin:0 16px;background-color:#fff;transform: translate(0, -50%);border-radius:10px;padding:20px 0;font-size:16px;color:#333;box-shadow:0 0 5px #999;position:relative;z-index:100;}
.detail-content .content-top .top-list{text-align:center;margin-left:10px;}
.detail-content .content-top .top-list .list-title{font-size:20px;color:#333;}
.detail-content .content-top .top-list .list-text{font-size:16px;color:#666;margin-top:5px;}
.detail-content .content-main{margin-top:-25px;margin-bottom:10px;}
.detail-content .content-item{margin:25px 16px 0;border-radius:8px;position:relative;}
.detail-content .content-item.te{padding:0 0 20px;background-color:#f5f5f5;}
.detail-content .content-item.te1{padding-bottom:0;}
.detail-content .content-item .item-title.te{position:absolute;top:-16px;left:10px;font-weight:100;line-height:33px;width:76px;text-align:center;background-color:#B7212B;color:#fff;border-radius:8px;}
.detail-content .content-item .item-title{font-size:16px;color:#333;width:100%;text-align:left;margin-bottom:12px;font-weight:bold;}
.detail-content .content-item.te .item-title{border-bottom:none;color:#666;}
.detail-content .content-item .item-box{padding:40px 10px 10px;box-shadow:0 0 5px #999;border-radius:10px;background-color:#fff;}
.detail-content .content-item .item-box.te{padding-bottom:0;}
.detail-content .content-item .item-box .item-text{font-size:12px;line-height:16px;color:#666;}
.detail-content .content-item .item-box .item-tag .tag-list{display:inline-block;padding:0 11px;line-height:18px;background-color:#B7212B;color:#fff;border-radius:18px;margin-right:5px;margin-bottom:5px;font-size:12px;}
.detail-content .content-item .item-box .item-tag .tag-text{font-size:12px;line-height:14px;color:#666;}
.detail-content .content-item .box-list{box-shadow:0 0 5px #999;border-radius:10px;overflow:hidden;}
.detail-content .content-item .box-list .list-info{display:flex;border-bottom:none;font-size:14px;}
.detail-content .content-item .box-list .list-info:last-child{border-bottom:1px solid #e3e3e3;}
.detail-content .content-item .box-list .list-info:last-child .info-left{border-bottom:none;}
.detail-content .content-item .box-list .list-info:last-child .info-rig{border-bottom:none;}
.detail-content .content-item .box-list .list-info .info-left{width:30%;text-align:center;padding:16px 10px;background-color:#B7212B;border-bottom:1px solid #fff;color:#fff;}
.detail-content .content-item .box-list .list-info .info-rig{width:70%;padding:8px 10px;color:#666;border-bottom:1px solid #e6e6e6;}
.detail-content .content-item .box-list .list-info .info-title{width:50%;padding:8px5px;background-color:#B7212B;border-right:1px solid #e3e3e3;color:#fff;}
.detail-content .content-item .box-list .list-info .info-title:last-child{border-right:none;}
.detail-content .content-item .box-list .list-info .info-text{width:50%;padding:8px 5px;border-right:1px solid #e3e3e3;color:#666;}
.detail-content .content-item .box-list .list-info .info-text:last-child{border-right:none;}
</style>
