<template>
  <div class="home-index">
    <div class="index-top">
      <img class="top-img" src="~@/assets/images/index-top-logo.jpg" alt="">
    </div>
    <div class="index-content">
      <p class="content-title">东华理工匈牙利合作项目</p>
      <div class="content-item">
        <div class="content-item-list" v-for="(item, index) in list" :key="index" @click="goPage(item.url)">
          <img class="list-img" :src="item.img" alt="">
          <div class="list-info">{{ item.title }}</div>
        </div>
      </div>
      <div class="content-box">
				<div class="content-btn">
					<p class="btn-self" @click.stop="goPage('/applyFor')">填写申请</p>
				</div>
			</div>
    </div>
    <navIndex :name="name"></navIndex>
  </div>
</template>

<script>
import dedazhishen from '@/assets/images/idnex-centent-dedazhishen.png'
import dongxia from '@/assets/images/idnex-centent-dongxia.png'
import qita from '@/assets/images/idnex-centent-qita.png'
import navIndex from '@/components/public/nav.vue'
export default {
  name: 'home-index',
  props: {},
  data () {
    return {
      name: '',
      list: [
        // {
        //   img: debulesen,
        //   title: '德布勒森大学硕士申请入口',
        //   url: '/masterApplication'
        // },
        {
          img: dongxia,
          title: '国际冬/夏令营申请入口',
          url: '/winterSummerCamp'
        },
        // {
        //   img: guangcai,
        //   title: '数学与数量经济学院入口',
        //   url: '/mathematicsAndQuantitativeEconomy'
        // },
        {
          img: qita,
          title: '匈牙利名校申硕入口',
          url: '/famousSchools'
        },
        {
          img: dedazhishen,
          title: '添加升学指导老师直接申请',
          url: '/directApplication'
        }
      ]
    }
  },
  components: {
    navIndex
  },
  computed: {},
  created () {
    document.title = "东华理工匈牙利升学申请中心"
    this.name = this.$route.name ? this.$route.name : 'home'
  },
  watch: {},
  methods: {
    goPage (url) {
      this.$router.push({
        path: url
      })
    }
  }
}
</script>

<style scoped>
.home-index{background-color:#fff;}
.index-top{padding:12px 16px;}
.index-top .top-img{width:100%;display: block;border-radius:8px;}
.index-content{padding:0 16px;}
.index-content .content-title{font-size:16px;font-weight:bold;border-bottom:1px solid #E0E0E0;padding-bottom:4px;padding-left:16px;position:relative;}
.index-content .content-title::after{position:absolute;content:'';display:block;width:8px;height:8px;left:0;top:50%;background-color:#000;border-radius:50%;transform:translate(0,-50%);}
.index-content .content-item .content-item-list{margin-top:12px;overflow:hidden;background-color:#F0F5F3;border-radius:8px;}
.index-content .content-item .content-item-list .list-img{display: block;float:left;width:230px;height:100px;}
.index-content .content-item .content-item-list .list-info{padding:16px 21px 16px 11px;margin-left:244px;font-size:16px;font-weight:600;}
</style>
