<template>
  <div class="direct-application-index">
    <div class="index-content">
      <img class="content-bg" src="~@/assets/images/direct-application-bg.png" alt="">
      <div class="content-info">
        <img class="info-logo" src="~@/assets/images/direct-application-deda-logo.png" alt="">
        <div class="info-self">
          <p class="self-title">匈牙利教育联盟</p>
        </div>
      </div>
      <img class="content-code" src="~@/assets/images/from-qrcod-1.png" alt="">
    </div>
    <div class="index-info">
      <p class="info-text">扫描上方二维码</p>
      <p class="info-text">添加升学老师</p>
      <p class="info-text">直接申请</p>
    </div>
    <navIndex :name="'directApplication'"></navIndex>
  </div>
</template>

<script>
import navIndex from '@/components/public/nav.vue'
export default {
  name: 'direct-application',
  props: {},
  data () {
    return {
    }
  },
  components: {
    navIndex
  },
  computed: {},
  created () {
    document.title = ''
  },
  watch: {},
  methods: {}
}
</script>

<style scoped>
.direct-application-index{height:100vh;max-height:100%;}
.index-content{position:relative;}
.index-content .content-bg{display:block;width:100%;height:360px;}
.index-content .content-info{position:absolute;top:105px;left:32px;overflow:hidden;color:#fff;}
.index-content .content-info .info-logo{display: block;width:72px;float:left;}
.index-content .content-info .info-self{margin-left:88px;}
.index-content .content-info .info-self .self-title{font-size:20px;font-weight:600;margin-bottom:8px;}
.index-content .content-info .info-self .self-text{font-size:16px;}
.index-content .content-code{position:absolute;left:50%;bottom:-90px;display: block;width:180px;transform: translate(-50%, 0);}
.index-info{display: block;text-align: center;margin-top:110px;font-size:16px;font-weight:600;line-height:24px;}
</style>
