<template>
  <div class="product-index">
    <div class="index-top">
      <img class="top-img" src="~@/assets/images/product-logo.png" alt="">
      <div class="top-list" v-if="type !== 'te'">
        <p class="list-btn" @click="visible = true">{{ text }} <i class="btn-icon"></i></p>
        <p class="list-btn" @click="visible2 = true"> {{ text2 }} <i class="btn-icon"></i></p>
        <p class="list-btn" @click="visible1 = true">{{ text1 }} <i class="btn-icon"></i></p>
      </div>
      <div class="top-list" v-else>
        <p class="list-btn te" @click="visible2 = true"> {{ text2 }} <i class="btn-icon"></i></p>
      </div>
    </div>
    <div class="index-content">
      <div class="content-item">
        <nut-infiniteloading @loadmore="onInfinite" :is-show-mod="true" :has-more="isHasMore" :is-loading="isLoading" loading-txt="加载中" unload-more-txt="没有了哦~" :threshold="200">
          <div class="item-list" v-for="(item, index) in list" :key="index" @click="goPage('/professionalDetails', item.id, item.name)">
            <p class="list-title"><i class="title-icon" v-if="item.is_hot === 1"></i> {{item.name}}</p>
            <p class="list-text">{{item.name_en}}</p>
            <span class="list-btn" @click.stop="apply('/applyFor', item.id)">立即申请</span>
          </div>
        </nut-infiniteloading>
      </div>
      <div class="content-box">
				<div class="content-btn">
					<p class="btn-self" @click.stop="goPage('/applyFor')">填写申请</p>
				</div>
			</div>
    </div>
    <nut-picker
      :is-visible="visible"
      :list-data="collegesList"
      @close="visible = false"
      @confirm="confirm"
    ></nut-picker>
    <nut-picker
      :is-visible="visible1"
      :list-data="degreeList"
      @close="visible1 = false"
      @confirm="confirm1"
    ></nut-picker>
    <nut-picker
      :is-visible="visible2"
      :list-data="facultyList"
      @close="visible2 = false"
      @confirm="confirm2"
    ></nut-picker>
    <navIndex></navIndex>
  </div>
</template>      

<script>
import Api from '@/api/api.js'
import navIndex from '@/components/public/nav.vue'
export default {
  name: 'product-index',
  props: {},
  data () {
    return {
      text: '学校名称',
      text1: '留学阶段',
      text2: '所属学院',
      visible: false,
      visible1: false,
      visible2: false,
      type: '',
      page: 1,
      limet: 10,
      isHasMore: true,
      isLoading: false,
      total_pages: 0,
      list: [],
      collegesList: [],
      facultyList: [],
      degreeList: [
        [
          {
            label: 3,
            value: '硕士'
          },
          {
            label: 4,
            value: '博士'
          }
        ]
      ],
      college_id: 0,
      degree_id: 0,
      faculty_id: 0
    }
  },
  components: {
    navIndex
  },
  computed: {},
  created () {
    document.title = "产品"
    this.type = this.$route.query.type ? this.$route.query.type : ''
    this.text = this.$route.query.name ? this.$route.query.name : '学校名称'
    this.college_id  = this.$route.query.id ? this.$route.query.id : 1
    this.getList()
    this.getCollegesList()
    
  },
  watch: {},
  methods: {
    goPage (url, id, title) {
      this.$router.push({
        path: url,
        query: {
          college_id: this.college_id,
          major_id: id,
          title: title
        }
      }) 
    },
    apply (url, id) {
      this.$router.push({
        path: url,
        query: {
          college_id: this.college_id,
          major_id: id
        }
      })
    },
    // 获取专业列表
    getList () {
      let that = this
      Api.post({
        url: 'majors',
        data: {
          page: this.page,
          limet: this.limet,
          college_id: this.type === 'te' ? 1 : this.college_id,
          degree_id: this.degree_id,
          faculty_id: this.faculty_id
        },
        success: function(res) {
          if (res.data && res.code === 200) {
            for (let i in res.data) {
              that.list.push(res.data[i])
            }
            that.total_pages = res.totalPages
          }
        }
      })
    },
    // 下拉加载
    onInfinite () {
      this.page++
      if (this.page <= this.total_pages) {
        this.getList()
      } else {
        this.isHasMore = false
        return false
      }
    },
    // 获取学院列表
    getFacultyList () {
      let that = this
      that.facultyList = []
      Api.post({
        url: 'faculty/list',
        data: {
          college_id: this.type === 'te' ? 1 : this.college_id,
        },
        success (res) {
          if (res.data && res.code === 200) {
            let list = []
            for (let i in res.data) {
              let data = {
                label: res.data[i].id,
                value: res.data[i].name
              }
              list.push(data)
            }
           that.facultyList.push(list)
          }
        }
      })
    },
    // 获取学校列表
    getCollegesList () {
      let that = this
      that.collegesList = []
      Api.post({
        url: 'colleges/list',
        data: {},
        success (res) {
          if (res.data && res.code === 200) {
            let list = []
            for (let i in res.data) {
              let data = {
                label: res.data[i].id,
                value: res.data[i].name
              }
              list.push(data)
            }
           that.collegesList.push(list)
           that.getFacultyList()
          }
        }
      })
    },
    // 选择学校
    confirm (index) {
      this.text = index[0].value
      this.college_id = index[0].label
      this.page = 1
      this.list = []
      this.getList()
      this.getFacultyList()
    },
    // 选择学历
    confirm1 (index) {
      this.text1 = index[0].value
      this.degree_id = index[0].label
      this.list = []
      this.page = 1
      this.getList()
    },
    // 选择学院
    confirm2 (index) {
      this.text2 = index[0].value
      this.faculty_id = index[0].label
      this.list = []
      this.page = 1
      this.getList()
    },
  }
}
</script>

<style scoped>
   .index-top{background-color:#fff;padding:16px 16px 0;}
.index-top .top-img{display: block;width:100%;}
.index-top .top-list{overflow:hidden;}

.index-top .top-list .list-btn.te{width:100%;}
.index-top .top-list .list-btn{float:left;width:33.33%;font-size:12px;color:#333;line-height:36px;text-align:center;height:36px;}
.index-top .top-list .list-btn .btn-icon{display:inline-block;width:4px;height:4px;border-left:2px solid #333;border-top:2px solid #333;transform: rotate(-135deg) translate(0, 3px);}
.index-content{padding:16px;}
.index-content .item-list{margin-bottom:16px;background-color:#fff;border-radius:8px;padding:16px;position:relative;}
.index-content .item-list .list-title{font-size:16px;color:#333;line-height:32px;margin-right:58px;}
.index-content .item-list .list-title .title-icon{display:inline-block;width:20px;height:20px;background:url('~@/assets/images/winter-summer-camp-news-icon-xialingying-active.png') center no-repeat;background-size:20px;}
.index-content .item-list .list-text{font-size:12px;color:#999;margin-right:58px;}
.index-content .item-list .list-btn{position: absolute;right:10px;top:50%;width:58px;line-height:24px;text-align: center;color:#fff;background-color:#B7212B;font-size:12px;transform: translate(0,-50%);border-radius:8px;}
</style>
