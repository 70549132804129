<template>
  <div class="winter-summer-camp-index">
    <div class="index-top">
      <img class="top-img" src="~@/assets/images/winter-summer-camp-logo.png" alt="">
      <div class="top-title">
        <p class="title-text">国际</p>
        <p class="title-text">冬/夏令营项目</p>
      </div>
    </div>
    <div class="index-item">
      <div class="item-list" @click="goPage('/dedaCamp')">
        <img class="list-img" src="~@/assets/images/winter-summer-camp-deda.png" alt="">
        <p class="list-text">德布勒森大学冬/夏令营<i class="text-icon"></i></p>
      </div>
      <div class="item-list" @click="goPage('/nanyangSummerCamp')">
        <img class="list-img" src="~@/assets/images/winter-summer-camp-nanyang.png" alt="">
        <p class="list-text">南洋理工大学冬/夏令营<i class="text-icon"></i></p>
      </div>
    </div>
    <div class="index-content">
      <div class="content-material">
        <el-carousel height="95px" indicator-position="none">
          <el-carousel-item v-for="(item, index) in materials" :key="index">
            <div class="material-info">
              <div class="info-img">
                <img class="img-self" src="~@/assets/images/winter-summer-camp-copywriting.png" alt="">
                <p class="img-text">最新资料</p>
              </div>
              <div class="info-copywriting">
                <p class="copywriting-title">{{item.title}}</p>
                <p class="copywriting-text">{{item.name}}</p>
                <p class="copywriting-btn">
                  <span class="btn-self" @click="goPpt(item.files)">在线查看</span>
                </p>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="content-news">
        <p class="news-title" @click="goPage('/news')">往期新闻 
          <span class="title-more">更多></span>
        </p>
        <div class="news-item">
          <div class="item-title">
            <p class="title-list" :class="[{'active': type === item.type}, item.icon]" v-for="(item, index) in title" :key="index" @click="activeClich(item.type)">{{ item.title }}</p>
          </div>
          <div class="item-list">
            <div class="list-self" v-for="(item, index) in newsList" :key="index" @click="goNews(item.href)">
              <img class="self-img" :src="item.img" alt="">
              <div class="self-info">
                <p class="info-title">{{ item.title }}</p>
                <p class="info-text">{{item.content}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-material video">
        <p class="video-title">视频风采</p>
        <div class="material-video">
          <el-carousel height="260px" indicator-position="none">
            <el-carousel-item v-for="(item, index) in videosList" :key="index">
              <div class="video-list">
                <video class="video-list-self" :src="item.video_path" :poster="item.image" controls></video>
                <div class="video-list-text">
                  <i class="text-icon"></i>
                  <p class="text-title">{{item.title}}</p>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <navIndex></navIndex>
  </div>
</template>

<script>
import Api from '@/api/api.js'
import navIndex from '@/components/public/nav.vue'
export default {
  name: 'winterSummerCamp',
  props: {},
  data () {
    return {
      type: 1,
      title: [
        {
          type: 1,
          title: '夏令营风采',
          icon: 'xialingying'
        },
        {
          type: 2,
          title: '匈牙利风景',
          icon: 'xiongyali'
        },
        {
          type: 3,
          title: '往期精彩',
          icon: 'wangqi'
        }
      ],
      newsList: [],
      materials: [],
      videosList: []
    }
  },
  components: {
    navIndex
  },
  computed: {},
  created () {
    document.title = "国际冬/夏令营"
    this.getNewsList()
    this.getMaterials(1)
    this.getMaterials(2)
    this.getVideos()
  },
  watch: {},
  methods: {
    activeClich (type) {
      this.type = type
      this.getNewsList()
    },
    goPage (url) {
      this.$router.push({
        path: url
      })
    },
    getNewsList () {
      let that = this
      Api.post({
        url: 'news',
        data: {
          type: this.type,
          limit: 3
        },
        success: function(res) {
          if (res.data && res.code === 200) {
            that.newsList = res.data
          } 
        }
      })
    },
    getMaterials (type) {
      let that = this
      Api.post({
        url: 'materials',
        data: {
          type: type
        },
        success (res) {
          if (res.data && res.code === 200) {
            that.materials.push(res.data[0])
          }
        }
      })
    },
    goNews (url) {
      window.open(url)
    },
    getVideos () {
      let that = this
      Api.post({
        url: 'videos',
        data: {
          is_country: 1
        },
        success (res) {
          if (res.code === 200 && res.data) {
            that.videosList = res.data
          }
        }
      })
    },
    goPpt (url) {
      window.open(url)
    }
  }
}
</script>

<style scoped>
.index-top{padding:16px;position:relative;}
.index-top .top-img{display:block;width:100%;}
.index-top .top-title{position:absolute;left:32px;bottom:26px;font-size:22px;color:#fff;}
.index-item{padding:0 16px;overflow:hidden;}
.index-item .item-list{float:left;width:50%;box-sizing:border-box;}
.index-item .item-list .list-img{display: block;width:100%;margin:0 auto 8px;}
.index-item .item-list:first-child{padding-right:5px;}
.index-item .item-list:last-child{padding-left:5px;}
.index-item .item-list .list-text{font-size:12px;color:#333;text-align:center;}
.index-item .item-list .list-text .text-icon{display:inline-block;width:16px;height:8px;background:url('~@/assets/images/winter-summer-camp-icon.png') no-repeat center;background-size:8px;}
.index-content{padding:0 16px;margin-top:16px;}
.index-content .content-material{padding:16px;margin-bottom:16px;}
.index-content .content-material.video{background-color:transparent;padding:0;}
.index-content .content-material .material-video{background-color:#fff;padding:0 16px;}
.index-content .content-material .video-title{padding:0 16px;font-size:16px;font-weight:600;color:#333;margin-bottom:12px;}
.content-news{margin-bottom:16px;}
.content-news .news-title{padding:0 16px;font-size:16px;font-weight:600;color:#333;margin-bottom:12px;}
.content-news .news-title .title-more{font-size:12px;color:#999;line-height:16px;float:right;}
.content-news .news-item{background-color:#fff;border-radius:8px;padding:16px;}
.content-news .news-item .item-title{overflow:hidden;}
.content-news .news-item .item-title .title-list{float:left;width:33.33%;box-sizing: border-box;padding-left:25px;font-size:14px;color:#999;background:url('~@/assets/images/winter-summer-camp-news-icon-xialingying.png') left center no-repeat;background-size:20px;}
.content-news .news-item .item-title .title-list.xialingying{background-image:url('~@/assets/images/winter-summer-camp-news-icon-xialingying.png');}
.content-news .news-item .item-title .title-list.xiongyali{background-image:url('~@/assets/images/winter-summer-camp-news-icon-xiongyali.png');}
.content-news .news-item .item-title .title-list.wangqi{background-image:url('~@/assets/images/winter-summer-camp-news-icon-wangqi.png');}
.content-news .news-item .item-title .title-list.xialingying.active{background-image:url('~@/assets/images/winter-summer-camp-news-icon-xialingying-active.png');}
.content-news .news-item .item-title .title-list.xiongyali.active{background-image:url('~@/assets/images/winter-summer-camp-news-icon-xiongyali-active.png');}
.content-news .news-item .item-title .title-list.wangqi.active{background-image:url('~@/assets/images/winter-summer-camp-news-icon-wangqi-active.png');}
.content-news .news-item .item-title .title-list.active{color:#333;}
.content-news .news-item .item-list{margin-top:20px;}
.content-news .news-item .item-list .list-self{margin-bottom:16px;overflow:hidden;}
.content-news .news-item .item-list .list-self:last-child{margin-bottom:0;}
.content-news .news-item .item-list .list-self .self-img{display:block;width:48px;height:48px;float:left;}
.content-news .news-item .item-list .list-self .self-info{margin-left:60px;}
.content-news .news-item .item-list .list-self .self-info .info-title{font-size:12px;color:#000;margin-bottom:6px;}
.content-news .news-item .item-list .list-self .self-info .info-text{font-size:12px;color:#999;}
</style>
