<template>
  <div class="apply-for-index">
    <div class="index-from">
      <p class="from-title">姓 名</p>
      <el-input class="from-ipt" v-model="from.name" placeholder=""></el-input>
      <p class="from-title">联系方式</p>
      <el-input class="from-ipt" v-model="from.phone" placeholder="填入手机号码"></el-input>
      <p class="from-title">年 级</p>
      <el-input class="from-ipt" v-model="from.grade" placeholder=""></el-input>
      <p class="from-title">班 级</p>
      <el-input class="from-ipt" v-model="from.classes" placeholder=""></el-input>
      <p class="from-btn" @click="submit">提交申请</p>
    </div>
    <navIndex></navIndex>
  </div>
</template>

<script>
import Api from '@/api/api.js'
import navIndex from '@/components/public/nav.vue'
export default {
  name: 'apply-for-special',
  props: {},
  data () {
    return {
      from: {
        name: '',
        phone: '',
        grade: '',
        classes: '',
        school_id: 2,
        type: 2
      }
    }
  },
  components: {
    navIndex
  },
  computed: {},
  created () {
    document.title = '3加1申请'
  },
  watch: {},
  methods: {
    submit () {
      let that = this
      if (this.from.name === '') {
        this.$toast.text('请输入您的姓名')
        return false
      }
      if (this.from.phone === '') {
        this.$toast.text('请输入您的手机号')
        return false
      }
      if (this.from.grade === '') {
        this.$toast.text('请输入您的学校')
        return false
      }
      if (this.from.classes === '') {
        this.$toast.text('请输入您的专业')
        return false
      }
      Api.post({
        url: 'applications/store',
        data: this.from,
        success (res) {
          if (res.code === 200 && res.data) {
            that.$toast.text('提交成功!升学老师将会在24小时之内联系您!')
            that.$router.go(-1)
            that.from = {
              name: '',
              phone: '',
              grade: '',
              classes: '',
              type: 2
            }
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.apply-for-index{background-color:#fff;padding:16px;}
.from-title{font-size:14px;margin-bottom:10px;}
.from-ipt{width:100%;margin-bottom:10px;}
.from-btn{display: block;width:294px;text-align: center;line-height:36px;color:#fff;background-color:#B7212B;margin:20px auto;border-radius:32px;}
</style>
