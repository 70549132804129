<template>
  <div class="cooperate-index">
    <div class="index-top">
      <img class="top-img" src="~@/assets/images/index-top-logo.jpg" alt="">
    </div>
    <div class="index-content">
      <p class="content-title">广西财经学院与德布勒森大学合作大事记</p>
      <ul class="content-item">
        <li class="item-list" v-for="(item, index) in list" :key="index">
          <img class="list-img" :src="item.img" alt="">
          <div class="list-info">
            <p class="info-title">{{ item.title }}</p>
            <p class="info-text">{{ item.summary }}</p>
          </div>
        </li>
      </ul>
    </div>
    <navIndex :name="'cooperate'"></navIndex>
  </div>
</template>

<script>
import Api from '@/api/api.js'
import navIndex from '@/components/public/nav.vue'
export default {
  name: 'cooperate-index',
  props: {},
  data () { 
    return {
      list: []
    }
  },
  components: {
    navIndex
  },
  computed: {},
  created () {
    document.title = '两校合作咨询'
    this.getList()
  },
  watch: {},
  methods: {
    getList () {
      let that = this
      Api.post({
        url: 'cooperateNews',
        data: {},
        success: function(res) {
          if (res.data && res.code === 200) {
            that.list = res.data
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.cooperate-index{background-color:#fff;}
.index-top .top-img{display: block;width:100%;}
.index-content{padding:16px;}
.index-content .content-title{position:relative;text-align: center;font-size:16px;color:#333;padding-bottom:12px;}
.index-content .content-title::after{position: absolute;content:'';width:57px;height:2px;display: block;left:50%;bottom:0;transform:translate(-50%,0);background-color:#B7212B;}
.index-content .content-item{margin-top:10px;}
.index-content .item-list{overflow:hidden;margin-bottom:12px;}
.index-content .item-list .list-img{display: block;float:left;width:120px;height:94px;}
.index-content .item-list .list-info{border-bottom:1px solid #e0e0e0;margin-left:130px;height:103px;}
.index-content .item-list .list-info .info-title{font-size:12px;font-weight:bold;margin-bottom:2px;line-height:20px;}
.index-content .item-list .list-info .info-text{font-size:12px;line-height:20px;margin-bottom:12px;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:4;-webkit-box-orient:vertical;word-break: break-all;}
</style>
