<template>
  <div class="apply-for-index">
    <div class="index-from">
      <p class="from-title">姓 名</p>
      <el-input class="from-ipt" v-model="from.name" placeholder=""></el-input>
      <p class="from-title">联系方式</p>
      <el-input class="from-ipt" v-model="from.phone" placeholder="填入手机号码"></el-input>
      <p class="from-title">申请学校</p>
      <el-select class="from-ipt" v-model="from.college_id" placeholder="" @change="getMajorList">
        <el-option v-for="(item, index) in collegeList" :key="index" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <p class="from-title">申请专业</p>
      <el-select class="from-ipt" v-model="from.major_id" placeholder="" :disabled="from.college_id === ''">
        <el-option v-for="(item, index) in majorsList" :key="index" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <p class="from-btn" @click="submit">提交申请</p>
    </div>
    <navIndex></navIndex>
  </div>
</template>

<script>
import Api from '@/api/api.js'
import navIndex from '@/components/public/nav.vue'
export default {
  name: 'apply-for',
  props: {},
  data () {
    return {
      from: {
        name: '',
        phone: '',
        college_id: '',
        major_id: '',
        school_id: 2,
        type: 1
      },
      collegeList: [],
      majorsList: []
    }
  },
  components: {
    navIndex
  },
  computed: {},
  created () {
    document.title = '填写申请'
    this.getCollege()
    this.from.college_id = Number(this.$route.query.college_id) ? Number(this.$route.query.college_id) : ''
    if (Number(this.from.college_id) > 0) {
      this.getMajorList()
      this.from.major_id = Number(this.$route.query.major_id) ? Number(this.$route.query.major_id) : ''
    }
  },
  watch: {},
  methods: {
    getCollege () {
      let that = this
      Api.post({
        url: 'colleges/list',
        data: {},
        success: function(res) {
          if (res.data && res.code === 200) {
            for (let i in res.data) {
              let data = {
                value: res.data[i].id,
                label: res.data[i].name
              }
              that.collegeList.push(data)
            }
          }
        }
      })
    },
    getMajorList () {
      this.from.major_id = ''
      let that = this
      that.majorsList = []
      Api.post({
        url: 'majors/list',
        data: {
          college_id: this.from.college_id
        },
        success: function(res) {
          if (res.data && res.code === 200) {
            for (let i in res.data) {
              let data = {
                value: res.data[i].id,
                label: res.data[i].name
              }
              that.majorsList.push(data)
            }
          }
        }
      })
    },
    submit () {
      let that = this
      if (this.from.name === '') {
        this.$toast.text('请输入您的姓名')
        return false
      }
      if (this.from.phone === '') {
        this.$toast.text('请输入您的手机号')
        return false
      }
      if (this.from.college_id === '') {
        this.$toast.text('请选择学校')
        return false
      }
      if (this.from.major_id === '') {
        this.$toast.text('请选择专业')
        return false
      }
      Api.post({
        url: 'applications/store',
        data: this.from,
        success (res) {
          if (res.code === 200 && res.data) {
            that.$toast.text('提交成功!升学老师将会在24小时之内联系您!')
            that.$router.go(-1)
            that.from = {
              name: '',
              phone: '',
              college_id: '',
              major_id: '',
              type: 1
            }
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.apply-for-index{background-color:#fff;padding:16px;}
.from-title{font-size:14px;margin-bottom:10px;}
.from-ipt{width:100%;margin-bottom:10px;}
.from-btn{display: block;width:294px;text-align: center;line-height:36px;color:#fff;background-color:#B7212B;margin:20px auto;border-radius:32px;}
</style>
