<template>
  <div class="master-application">
    <div class="public-top">
      <img class="top-logo" src="~@/assets/images/master-application-top-logo.png" alt="">
      <p class="top-title">德布勒森大学</p>
      <p class="top-text">成立于1538年</p>
      <p class="top-text size">德布勒森作为一所在欧洲有高影响的研究型公立大学，它为就读学生提供高水平教育服务和现代化设施，其教育质量一流，提供具有国际竞争力的学位，即使在欧洲经济区（EEA)以外的国家也得到广泛认可，是中欧顶尖的教育机构之一。</p>
      <div class="top-info">
        <p class="info-title">德布勒森研究生专业60+</p>
        <p class="info-text">
          <span class="text-self">8个校区</span>
          <span class="text-self">14个院系</span>
          <span class="text-self">100+ 授课大厅</span>
        </p>
        <p class="info-btn" @click="goPage('/product', 'te')">立即申请</p>
      </div>
    </div>
    <div class="index-content">
      <div class="content-advantage">
        <div class="advantage-info">
          <p class="info-title">留学优势</p>
          <table class="info-item" bordercolor="#fff" border="1" w>
            <tr>
              <td class="item-td">
                <p class="item-text te">免托福雅思</p>
              </td>
              <td class="item-td">
                <p class="item-text te">485年公立名校</p>
              </td>
            </tr>
            <tr>
              <td class="item-td">
                <p class="item-text te">持匈牙利学签</p>
                <p class="item-text">可免签畅游26国</p>
              </td>
              <td class="item-td">
                <P class="item-text te">工薪学费</P>
                <P class="item-text">留学性价比高</P>
              </td>
            </tr>
            <tr>
              <td class="item-td">
                <p class="item-text te">欧标教育体系</p>
                <p class="item-text">毕业可全球就业</p>
              </td>
              <td class="item-td">
                <p class="item-text te">欧盟申根国</p>
                <p class="item-text">欧标英文授课</p>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="content-material">
        <p class="material-title">德大相关资料</p>
        <div class="material-info">
          <div class="info-img">
            <img class="img-self" src="~@/assets/images/master-application-copywriting.png" alt="">
            <p class="img-text">最新资料</p>
          </div>
          <div class="info-copywriting">
            <p class="copywriting-title">德布勒森大学PDF资料</p>
            <p class="copywriting-text">多个课程，多个专业，下载文件，多些了解</p>
            <p class="copywriting-btn">
              <span class="btn-self" @click="goPTT(colleges.ppt)">在线查看</span>
            </p>
          </div>
        </div>
      </div>
      <div class="content-material">
        <p class="material-title">德大视频</p>
        <div class="material-video">
          <el-carousel height="260px" indicator-position="none">
            <el-carousel-item v-for="(item, index) in colleges.video" :key="index">
              <div class="video-list">
                <video class="video-list-self" :src="item.vdo" :poster="item.img" controls></video>
                <div class="video-list-text">
                  <i class="text-icon"></i>
                  <p class="text-title">{{item.title}}</p>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="content-material">
        <p class="material-title">德大风采</p>
        <el-carousel height="240px" indicator-position="none" class="elegant-demeanour" :autoplay="false">
          <el-carousel-item v-for="(item, index) in colleges.background_image" :key="index">
            <img class="elegant-demeanour-img" :src="item.img" alt="">
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <navIndex></navIndex>
  </div>
</template>

<script>
import Api from '@/api/api.js'
import navIndex from '@/components/public/nav.vue'
export default {
  name: 'master-application',
  props: {},
  data () {
    return {
      colleges: {}
    }
  },
  components: {
    navIndex
  },
  computed: {},
  created () {
    document.title = "德布勒森大学硕士申请"
    this.getColleges()
  },
  watch: {},
  methods: {
    goPage (url, type) {
      this.$router.push({
        path: url,
        query: {
          type: type
        }
      })
    },
    getColleges () {
      let that = this
      Api.post({
        url: 'colleges/show',
        data: {
          id: '1'
        },
        success (res) {
          if (res.data && res.code === 200) {
            that.colleges = res.data
          }
        }
      })
    },
    goPTT (url) {
      window.open(url)
    }
  }
}
</script>

<style scoped>
.master-application{display:block;overflow:hidden;padding:0 16px;}
.index-content{margin-top:34px;}
.index-content .content-advantage{background-color:#fff;padding:8px;border-radius:8px;margin-bottom:8px;}
.index-content .content-advantage .advantage-info{border-radius:8px;overflow:hidden;}
.index-content .content-advantage .advantage-info .info-title{font-size:14px;color: #fff;background-color:#004735;line-height:40px;padding-left:16px;}
.index-content .content-advantage .advantage-info .info-item{background-color:#F0F5F3;overflow:hidden;width:100%;}
.index-content .content-advantage .advantage-info .info-item .item-td{padding:10px 0;}
.index-content .content-advantage .advantage-info .info-item .item-text{font-size:16px;padding-left:32px;}
.index-content .content-advantage .advantage-info .info-item .item-text.te{position:relative;}
.index-content .content-advantage .advantage-info .info-item .item-text.te::after{content:'';position:absolute;width:8px;height:8px;background-color:#B7212B;left:10px;top:50%;transform: translate(0,-50%);border-radius:88px;}
.elegant-demeanour .elegant-demeanour-img{display:block;width:100%;height:100%;}
</style>
