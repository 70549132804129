<template>
  <div class="mathematics-and-quantitative-economy-index">
    <div class="public-top">
      <img class="top-logo" src="~@/assets/images/mathematicsAndQuantitativeEconomy-logo.png" alt="">
      <p class="top-title">金融数学3+1·双学位</p>
      <p class="top-text">广西财经学院&德布勒森大学</p>
      <p class="top-text size">期间如本项目的学生达到匈牙利德布勒森大学的入学要求并自愿前往该校就读本科最后一年，完成所有学分并成绩合格者可获得匈牙利德布勒森大学数学理学学士学位，同时获得广西财经学院金融数学普通本科学历毕业证书和经济学学士学位。</p>
      <div class="top-info">
        <p class="info-title">主要课程28项</p>
        <div class="info-text">
          <p class="text-self te">·在国内学习期间，每人每学年学费为35000元人民币</p>
          <p class="text-self te">·在匈牙利学习期间，每人每学年学费约为46000元人民币</p>
        </div>
        <p class="info-btn" @click="goPage('/applyForSpecial')">立即申请</p>
      </div>
    </div>
    <div class="index-content">
      <div class="content-material">
        <div class="material-info">
          <div class="info-img">
            <img class="img-self" src="~@/assets/images/mathematicsAndQuantitativeEconomy-copywriting.png" alt="">
            <p class="img-text">最新资料</p>
          </div>
          <div class="info-copywriting">
            <p class="copywriting-title">{{ materials.name }}</p>
            <p class="copywriting-text">{{ materials.title }}</p>
            <p class="copywriting-btn">
              <span class="btn-self" @click="goPTT(materials.files)">在线查看</span>
            </p>
          </div>
        </div>
      </div>
      <div class="content-camp">
        <p class="camp-title" @click="goPage('/dedaCamp')">数学与数量经济学院冬/夏令营 <i class="title-icon"></i></p>
        <img class="camp-img" src="~@/assets/images/mathematicsAndQuantitativeEconomy-camp.png" alt="">
      </div>
      <div class="content-camp">
        <p class="camp-title">本学院对应德大申请专业</p>
        <div class="camp-info">
          <div class="info-item">
            <div class="item-list">
              <img class="list-img" src="~@/assets/images/gxcj-logo.png" alt="">
              <p class="list-text">广西财经学院</p>
            </div>
            <div class="item-list">
              <img class="list-img" src="~@/assets/images/deda-logo.png" alt="">
              <p class="list-text">德布勒森大学</p>
            </div>
          </div>
          <div class="info-table">
            <p class="table-title">商科方向</p>
            <table class="table-self" border="1" bordercolor="#e6e6e6" cellspacing="0">
              <tr>
                <th>
                  <p class="table-lf">数学与应用数学</p>
                </th>
                <th rowspan="2">
                  <p class="table-rig">工商管理专业</p>
                  <p class="table-rig-clg">学费：6000美元/年</p>
                </th>
              </tr>
              <tr>
                <th>
                  <p class="table-lf">信息与计算科学</p>
                </th>
              </tr>
              <tr>
                <th>
                  <p class="table-lf">金融数学</p>
                </th>
                <th rowspan="2">
                  <p class="table-rig">商务和市场营销专业</p>
                  <p class="table-rig-clg">学费：6000美元/年</p>
                </th>
              </tr>
              <tr>
                <th>
                  <p class="table-lf">金融数学(中匈合作办学)</p>
                </th>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <navIndex></navIndex>
  </div>
</template>

<script>
import Api from '@/api/api.js'
import navIndex from '@/components/public/nav.vue'
export default {
  name: 'MathematicsAndQuantitativeEconomy',
  props: {},
  data () {
    return {
      materials: {}
    }
  },
  components: {
    navIndex
  },
  computed: {},
  created () {
    document.title = "数学与数量经济学院"
    this.getMaterials()
  },
  watch: {},
  methods: {
    goPage (url) {
      this.$router.push({
        path: url
      })
    },
    getMaterials () {
      let that = this
      Api.post({
        url: 'materials',
        data: {
          type: '3'
        },
        success (res) {
          if (res.data && res.code === 200) {
            that.materials = res.data[0]
          }
        }
      })
    },
    goPTT (url) {
      window.open(url)
    }
  }
}
</script>

<style scoped>
.mathematics-and-quantitative-economy-index{background-color:#fff;padding:16px;}
.public-top{background-color:#214896;}
.index-content{margin-top:70px;}
.index-content .content-material{background-color:#F0F5F3;padding:16px;}
.content-camp .camp-title{line-height:48px;font-size:16px;color:#333;font-weight:600;}
.content-camp .camp-title .title-icon{display: block;width:10px;height:10px;float: right;border-right:2px solid #333;border-top:2px solid #333;transform: rotate(45deg);margin-top:14px;}
.content-camp .camp-info{background-color:#F0F5F3;border-radius:16px;padding:16px;}
.content-camp .camp-info .info-item{overflow:hidden;}
.content-camp .camp-info .info-item .item-list{float:left;width:50%;overflow:hidden;padding:12px;box-sizing:border-box;}
.content-camp .camp-info .info-item .item-list .list-img{float: left;display: block;width:24px;}
.content-camp .camp-info .info-item .item-list .list-text{margin-left:36px;position:relative;}
.content-camp .camp-info .info-item .item-list .list-text::after{position: absolute;content:'';display: block;width:20PX;height:2PX;bottom:-4px;left:50%;transform:translate(-50%,0);background-color:#B7212B;}
.content-camp .camp-info .info-item .item-list:first-child .list-text::before{position: absolute;content:'';display:block;width:1px;height:16px;background-color:#e6e6e6;right:-10px;top:56%;transform: translate(0,-50%);}
.content-camp .camp-info .info-table{background-color:#fff;padding:12px 8px;}
.content-camp .camp-info .info-table .table-title{font-size:12px;line-height:32px;text-align: center;background-color:#004735;color:#fff;border-radius:4px;}
.content-camp .camp-info .info-table .table-lf{font-size:14px;font-weight:100;text-align:left;padding:10px 24px;position:relative;}
.content-camp .camp-info .info-table .table-lf::before{position: absolute;content:'';left:10px;top:50%;display: block;width:8px;height:8px;border-radius:8px;background-color:#B7212B;transform: translate(0,-50%);}
.table-self tr{padding:0;}
.content-camp .camp-info .info-table .table-rig{font-size:14px;font-weight:100;text-align:left;padding:10px 24px;position:relative;}
.content-camp .camp-info .info-table .table-rig::before{position: absolute;content:'';left:10px;top:50%;display: block;width:8px;height:8px;border-radius:8px;background-color:#B7212B;transform: translate(0,-50%);}
.content-camp .camp-info .info-table .table-rig-clg{font-size:14px;font-weight:100;text-align:left;padding:10px 14px;color: #a6a6a6;}
</style>
