<template>
  <div class="apply-for-index">
    <div class="index-from">
      <p class="from-title">姓 名</p>
      <el-input class="from-ipt" v-model="from.name" placeholder=""></el-input>
      <p class="from-title">联系方式</p>
      <el-input class="from-ipt" v-model="from.phone" placeholder="填入手机号码"></el-input>
      <p class="from-title">类型</p>
      <el-select class="from-ipt" v-model="from.type" placeholder="" :disabled="from.college_id === ''" v-if="this.type === 'deta'">
        <el-option v-for="(item, index) in typeList" :key="index" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-select class="from-ipt" v-model="from.type" placeholder="" :disabled="from.college_id === ''" v-if="this.type === 'nylg'">
        <el-option v-for="(item, index) in typeList1" :key="index" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <p class="from-btn" @click="submit">提交申请</p>
      <img class="from-qrcode" src="~@/assets/images/from-qrcod-1.png" alt="">
      <p class="from-text">扫描上方二维码</p>
      <p class="from-text">可直接添加升学老师进行申请</p>
    </div>
    <navIndex></navIndex>
  </div>
</template>

<script>
import Api from '@/api/api.js'
import navIndex from '@/components/public/nav.vue'
export default {
  name: 'apply-for-special',
  props: {},
  data () {
    return {
      from: {
        name: '',
        phone: '',
        school_id: 2,
        type: 2
      },
      type: '',
      typeList: [
        {
          label: '德大夏令营',
          value: 3
        },
        {
          label: '德大冬令营',
          value: 4
        }
      ],
      typeList1: [
        {
          label: '南洋理工夏令营',
          value: 6
        },
        {
          label: '南洋理工冬令营',
          value: 5
        }
      ]
    }
  },
  components: {
    navIndex
  },
  computed: {},
  created () {
    this.type = this.$route.query.type ? this.$route.query.type : ''
    if (this.type === 'deta') {
      this.from.type = 3
      document.title = '德布勒森大学申请'
    } else if (this.type === 'nylg') {
      this.from.type = 6
      document.title = '南洋理工大学申请'
    }
  },
  watch: {},
  methods: {
    submit () {
      let that = this
      if (this.from.name === '') {
        this.$toast.text('请输入您的姓名')
        return false
      }
      if (this.from.phone === '') {
        this.$toast.text('请输入您的手机号')
        return false
      }
      if (this.from.type === '') {
        this.$toast.text('请选择冬/夏令营')
        return false
      }
      Api.post({
        url: 'applications/store',
        data: this.from,
        success (res) {
          if (res.code === 200 && res.data) {
            that.$toast.text('提交成功!升学老师将会在24小时之内联系您!')
            that.$router.go(-1)
            that.from = {
              name: '',
              phone: ''
            }
            if (that.type === 'deta') {
              that.from.type = 3
            } else if (that.type === 'nylg') {
              that.from.type = 6
            }
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.apply-for-index{background-color:#fff;padding:16px;}
.from-title{font-size:14px;margin-bottom:10px;}
.from-ipt{width:100%;margin-bottom:10px;}
.from-btn{display: block;width:294px;text-align: center;line-height:36px;color:#fff;background-color:#B7212B;margin:20px auto;border-radius:32px;}
.from-text{text-align: center;font-size:14px;}
.from-qrcode{display: block;width:124px;margin:30px auto;}
</style>
