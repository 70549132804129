import { render, staticRenderFns } from "./dedaCamp.vue?vue&type=template&id=1449a166&scoped=true&"
import script from "./dedaCamp.vue?vue&type=script&lang=js&"
export * from "./dedaCamp.vue?vue&type=script&lang=js&"
import style0 from "./dedaCamp.vue?vue&type=style&index=0&id=1449a166&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1449a166",
  null
  
)

export default component.exports