import Vue from 'vue'
import Router from 'vue-router'
import index from '@/page/home/index'
import masterApplication from '@/page/menu/masterApplication'
import winterSummerCamp from '@/page/menu/winterSummerCamp'
import famousSchools from '@/page/menu/famousSchools'
import mathematicsAndQuantitativeEconomy from '@/page/menu/mathematicsAndQuantitativeEconomy'
import product from '@/page/menu/product'
import directApplication from '@/page/menu/directApplication'
import nanyangSummerCamp from '@/page/menu/nanyangSummerCamp'
import dedaCamp from '@/page/menu/dedaCamp'
import news from '@/page/menu/news'
import professionalDetails from '@/page/menu/professionalDetails'
import applyFor from '@/page/menu/applyFor'
import applyForSpecial from '@/page/menu/applyForSpecial'
import applyForSpecialCamp from '@/page/menu/applyForSpecialCamp'
import cooperate from '@/page/menu/cooperate'

const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    // 默认打开页面
    {
      path: '/',
      name: 'home',
      component: index
    },
    { //德布勒森大学硕士申请
      path: '/masterApplication',
      name: 'masterApplication',
      component: masterApplication
    },
    { //冬、夏令营
      path: '/winterSummerCamp',
      name: 'winterSummerCamp',
      component: winterSummerCamp
    },
    { //其他匈牙利名校申硕
      path: '/famousSchools',
      name: 'famousSchools',
      component: famousSchools
    },
    { //数学与数量经济学院
      path: '/mathematicsAndQuantitativeEconomy',
      name: 'mathematicsAndQuantitativeEconomy',
      component: mathematicsAndQuantitativeEconomy
    },
    { // 产品
      path: '/product',
      name: 'product',
      component: product
    },
    { // 产品详情
      path: '/professionalDetails',
      name: 'professionalDetails',
      component: professionalDetails
    },
    { // 直接联系老师
      path: '/directApplication',
      name: 'directApplication',
      component: directApplication
    },
    { // 南洋理工大学冬/夏令营
      path: '/nanyangSummerCamp',
      name: 'nanyangSummerCamp',
      component: nanyangSummerCamp
    },
    { // 德大夏令营
      path: '/dedaCamp',
      name: 'dedaCamp',
      component: dedaCamp
    },
    { // 新闻
      path: '/news',
      name: 'news',
      component: news
    },
    { // 申请
      path: '/applyFor',
      name: 'applyFor',
      component: applyFor
    },
    { // 特殊申请
      path: '/applyForSpecial',
      name: 'applyForSpecial',
      component: applyForSpecial
    },
    { // 特殊申请
      path: '/applyForSpecialCamp',
      name: 'applyForSpecialCamp',
      component: applyForSpecialCamp
    },
    { // 两校合作咨询
      path: '/cooperate',
      name: 'cooperate',
      component: cooperate
    } 
  ]
})
