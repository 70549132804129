<template>
  <div class="deda-camp-index">
    <div class="index-top">
      <img class="top-img" src="~@/assets/images/deda-camp-top.png" alt="">
      <div class="top-info">
        <p class="info-title">德布勒森大学冬/夏令营</p>
        <p class="info-tag">
          <span class="tag-list">两国行程</span>
          <span class="tag-list">语言学习</span>
          <span class="tag-list">学术课程</span>
          <span class="tag-list">城市参观</span>
        </p>
        <p class="info-text">冬令营: 2024年1月</p>
        <p class="info-text">夏令营: 2024年7月/8月（两期）15人成团</p>
      </div>
    </div>
    <div class="index-content">
      <div class="content-item">
        <p class="item-title">夏令营风采</p>
        <div class="item-elegant">
          <el-carousel height="240px" indicator-position="none" class="elegant-demeanour" v-if="colleges.camp_image.length > 0">
            <el-carousel-item v-for="(item, index) in colleges.camp_image" :key="index">
              <img class="elegant-img" :src="item.img" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="content-item">
        <p class="item-title">项目内容</p>
        <div class="item-advantage">
          <div class="advantage-box">
            <div class="advantage-list w30">
              <p class="list-text">经济学基础</p>
            </div>
            <div class="advantage-list">
              <p class="list-text">商业中的游戏化/数据可视化</p>
            </div>
            <div class="advantage-list w30">
              <p class="list-text">业务规划</p>
            </div>
            <div class="advantage-list">
              <p class="list-text">农业和粮食安全方面的全球挑战</p>
            </div>
            <div class="advantage-list w30">
              <p class="list-text">物流流程开发</p>
            </div>
            <div class="advantage-list">
              <p class="list-text">商业和经济中的数学问题</p>
            </div>
            <div class="advantage-list w30">
              <p class="list-text">发展创业精神</p>
            </div>
            <div class="advantage-list">
              <p class="list-text">跨文化交流/谈判技巧</p>
            </div>
            <div class="advantage-list w30">
              <p class="list-text">组织行为</p>
            </div>
          </div>
        </div>
      </div>
      <div class="content-item">
        <p class="item-title">行程安排</p>
        <div class="item-arrange">
          <img class="arrange-img" src="~@/assets/images/dada-summer-camp-arrange.jpg" alt="">
        </div>
      </div>
      <div class="content-item">
        <p class="item-title">报名流程</p>
        <div class="item-procedure">
          <div class="procedure-info">
            <div class="info-list">
              <span class="list-title">STEP1</span>
              <span class="list-text">·在线填写申请表，等待老师联系</span>
            </div>
            <div class="info-list">
              <span class="list-title">STEP2</span>
              <span class="list-text">·提交护照扫描件，交纳报名费</span>
            </div>
            <div class="info-list">
              <span class="list-title">STEP3</span>
              <span class="list-text">·完成签证办理并购买机票</span>
            </div>
            <div class="info-list">
              <span class="list-title">STEP4</span>
              <span class="list-text">·行前说明</span>
            </div>
            <div class="info-list">
              <span class="list-title">STEP5</span>
              <span class="list-text">·正式出行</span>
            </div>
          </div>
        </div>
      </div>
      <div class="content-box">
        <div class="content-btn">
          <p class="btn-self" @click="goPage('/applyForSpecialCamp')">填写申请</p>
        </div>
      </div>
    </div>
    <navIndex></navIndex>
  </div>
</template>

<script>
import Api from '@/api/api.js'
import navIndex from '@/components/public/nav.vue'
export default {
  name: 'deda-camp',
  props: {},
  data () {
    return {
      colleges: {}
    }
  },
  components: {
    navIndex
  },
  computed: {},
  created () {
    document.title = '德布勒森大学冬/夏令营'
    this.getColleges()
  },
  watch: {},
  methods: {
    goPage (url) {
      this.$router.push({
        path: url,
        query: {
          type: 'deta'
        }
      })
    },
    getColleges () {
      let that = this
      Api.post({
        url: 'colleges/show',
        data: {
          id: '1'
        },
        success (res) {
          if (res.data && res.code === 200) {
            that.colleges = res.data
          }
        }
      })
    },
  }
}
</script>

<style scoped>
.deda-camp-index{background-color:#fff;}
.index-top{padding:16px;background-color:#F0F5F3;position:relative;}
.index-top .top-img{display:block;width:100%;}
.index-top .top-info{display:block;position:absolute;width:326px;left:50%;background-color:#fff;bottom:24px;transform: translate(-50%, 0);padding:10px 16px;box-sizing:border-box;border-radius:8px;}
.index-top .top-info .info-title{font-size:16px;margin-bottom:10px;}
.index-top .top-info .info-tag{overflow:hidden;border-bottom:1px solid #E0E0E0;font-size:12px;color:#999;}
.index-top .top-info .info-tag .tag-list{float:left;width:25%;margin-bottom:6px;padding-left:12px;box-sizing:border-box;position:relative;}
.index-top .top-info .info-tag .tag-list::before{content:'';width:8px;height:8px;background-color:#B7212B;position: absolute;left:0;top:50%;transform:translate(0,-50%);}
.index-top .top-info .info-text{font-size:14px;line-height:24px;}
.index-content .content-item{margin-top:12px;}
.index-content .content-item .item-title{padding:0 16px 12px;font-size:16px;color:#333;font-weight:bold;}
.index-content .content-item .item-elegant{padding:16px;background-color:#F0F5F3;border-radius:8px;}
.index-content .content-item .item-elegant .elegant-img{display:block;width:100%;height:100%;}
.index-content .content-item .item-advantage{padding:0 16px;}
.index-content .content-item .item-advantage .advantage-box{background-color:#F0F5F3;border-radius:8px;overflow:hidden;}
.index-content .content-item .item-advantage .advantage-list{padding:10px;float:left;width:60%;box-sizing:border-box;border-right:1px solid #fff;border-bottom:1px solid #fff;}
.index-content .content-item .item-advantage .advantage-list.w30{width:40%;}
.index-content .content-item .item-advantage .advantage-list .list-text{padding-left:16px;font-size:12px;background:url('~@/assets/images/deda-camp-icon.png') left 4px no-repeat;background-size:9px;}
.index-content .content-item .item-procedure{position:relative;padding:0 16px;}
.index-content .content-item .item-procedure .procedure-info{color:#fff;}
.index-content .content-item .item-procedure .procedure-info .info-list{line-height:28px;margin-bottom:4px;font-size:14px;}   
.index-content .content-item .item-procedure .procedure-info .info-list .list-title{display:inline-block;width:66px;text-align:center;line-height:28px;background-color:#B3040F;border-radius:8px;}
.index-content .content-item .item-procedure .procedure-info .info-list .list-text{display:inline-block;width:236px;border-bottom:1px solid #000;margin-left:10px;color:#000;}
</style>
