<template>
  <div class="public-nav">
    <div class="nav-item">
      <div class="item-list" :class="[{'active': type === item.type}]" v-for="(item, index) in list" :key="index" @click="goPage(item.url)">
        <i class="list-icon" :class="item.icon"></i>
        <p class="list-text">{{ item.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'public-nav',
  props: {
    name: {
      type: String,
      default: 'home'
    }
  },
  data () {
    return {
      type: 1,
      list: [
        {
          icon: 'home',
          text: '首页',
          type: 1,
          url: '/' 
        },
        {
          icon: 'cooperate',
          text: '联系我们',
          type: 2,
          url: '/directApplication'
        }
      ]
    }
  },
  components: {},
  computed: {},
  created () {
    if (this.name === 'directApplication') {
      this.type = 2
    } else {
      this.type = 1
    }
  },
  watch: {
  },
  methods: {
    goPage (url) {
      this.$router.push({
        path: url
      })
    }
  }
}
</script>

<style scoped>
.public-nav{height:64px;}
.nav-item{position:fixed;left:50%;height:64px;bottom:0;max-width:750px;width:100%;transform:translate(-50%,0);background-color:#f5f5f5;overflow:hidden;z-index:1000;}
.nav-item .item-list{float:left;width:50%;text-align:center;font-size:10px;}
.nav-item .item-list .list-icon{display:block;width:24px;height:24px;background:url('~@/assets/images/nav-home.png') center;background-size:24px;margin:6px auto;}
.nav-item .item-list .list-icon.cooperate{background-image:url('~@/assets/images/nav-cooperate.png');}
.nav-item .item-list .list-icon.home{background-image:url('~@/assets/images/nav-home.png');}
.nav-item .item-list.active{color:#B7212B;}
.nav-item .item-list.active .list-icon.home{background-image:url('~@/assets/images/nav-home-active.png');}
.nav-item .item-list.active .list-icon.cooperate{background-image:url('~@/assets/images/nav-cooperate-active.png');}
</style>
